<template>
  <form @change="handleRadio" class="date-container d-flex" ref="dateContainer">
    <vs-radio v-model="picked" :val="$t('helpers.onPage')">
      {{ $t('helpers.onPage').toUpperCase() }}
    </vs-radio>
    <vs-radio v-model="picked" :val="$t('helpers.today')">
      {{ $t('helpers.today').toUpperCase() }}
    </vs-radio>
    <!-- <vs-radio v-model="picked" :val="$t('helpers.week')">
                  {{ $t('helpers.week').toUpperCase() }}
                </vs-radio> -->
    <vs-radio v-model="picked" :val="$t('helpers.month')">
      {{ $t('helpers.month').toUpperCase() }}
    </vs-radio>
  </form>
</template>

<script>
export default {
  data() {
    return {
      picked: this.$t('helpers.onPage'),
    };
  },
  methods: {
    handleRadio() {
      this.$emit('handleRadio', this.picked);
    },
  },
};
</script>
